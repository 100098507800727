<template>
  <div class="devcomp">
    <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" class="demo-form-inline">


            <el-form-item label="按所属用户筛选：">
              <el-select @change="onRefresh" v-model="usvalue" placeholder="请选择">
                <el-option v-for="item in uselect" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-left: 20px" label="按状态筛选：">
              <el-select @change="onRefresh" v-model="ssvalue" placeholder="请选择" style="width: 140px;">
                <el-option v-for="item in sselect" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="  ">
              <el-input clearable @keyup.enter.native="onRefresh()" @clear="onRefresh()" placeholder="搜索设备名"
                v-model="searchinput" style="width: 400px;">
                <template slot="prepend">设备名称查找:</template>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search">查询</el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="  ">
              <el-input clearable @keyup.enter.native="onRefresh()" @clear="onRefresh()" placeholder="支持模糊搜索编号SN"
                v-model="searchinputSN" style="width: 440px;">
                <template slot="prepend">设备编号(SN)查找:</template>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search">查询</el-button>
              </el-input>
            </el-form-item>

          </el-form>

        </div>
        <div class="search">
        </div>

        <el-table :data="tbdata" border stripe height="660" style="width: 100%; margin: 3px">
          <el-table-column prop="sn" align="center" label="SN" width="145">
          </el-table-column>
          <el-table-column prop="name" label="设备名称" align="center">
          </el-table-column>

          <el-table-column prop="model" width="80" label="型号" align="center">
          </el-table-column>

          <!-- <el-table-column prop="status" width="80" label="状态" align="center">
          </el-table-column> -->

          <el-table-column prop="status" width="90" label="状态" align="center">
            <template slot-scope="scope">
              <el-tag :type="scope.row.status === '在线'
                ? 'success'
                : scope.row.status === '离线'
                  ? 'info'
                  : 'danger'
                " disable-transitions>{{ scope.row.status }}</el-tag>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="counts" width="105" label="剩余次数" align="center">
          </el-table-column> -->

          <el-table-column label="剩余次数" width="90" align="center">
            <template #default="scope">
              <span :style="scope.row.counts < 10 ?
                'color: red;' : 'color: black;'">{{ scope.row.counts }} 次</span>
            </template>
          </el-table-column>

          <el-table-column prop="today" width="105" label="今日次数" align="center">
          </el-table-column>

          <el-table-column prop="total" width="105" label="总次数" align="center">
          </el-table-column>

          <el-table-column :width="btnwidth">
            <template slot="header">
              <el-button type="primary" icon="el-icon-refresh" style="margin-left: 30px;width: 160px"
                @click="onRefresh">刷新列表
              </el-button>
              <el-button type="primary" icon="el-icon-download" style="margin-left: 30px;width: 160px" plain
                @click="ExportDeviceExcel">导出设备列表
              </el-button>
            </template>
            <template slot-scope="scope">

              <el-popover placement="top" width="220" v-model="scope._vis_">
                <div style="text-align: center; margin: 0">
                  <p>设备远程启停控制</p>

                  <el-button size="mini" icon="el-icon-video-play" type="success" plain
                    @click="DeviceTrun(scope.$index, '1')">启动设备</el-button>
                  <el-button size="mini" icon="el-icon-video-pause" type="danger" plain
                    @click="DeviceTrun(scope.$index, '0')">停止设备</el-button>
                </div>
                <el-button size="mini" type="success" plain slot="reference">设备启停</el-button>
              </el-popover>

              <!-- <el-button size="mini" type="success" plain @click="DeviceTrun(scope.$index,'1')">启动设备</el-button>
              <el-button size="mini" type="danger" plain @click="DeviceTrun(scope.$index,'0')">停止设备</el-button> -->

              <span v-if="pg_list.device_config">
                <el-divider direction="vertical"></el-divider>
                <el-button size="mini" @click="OpenMapDialog(scope.$index)" icon="el-icon-location-outline"
                  plain>定位</el-button>
                <el-button style="margin-left: 10px" size="mini" @click="OpenDeviceConfigDialog(scope.$index)"
                  icon="el-icon-setting" plain>配置</el-button>
              </span>

              <span v-if="pg_list.charge_device">
                <el-divider direction="vertical"></el-divider>
                <el-button size="mini" @click="OpenRechargeDialog(scope.$index)" type="primary" plain>次数充值</el-button>
              </span>
              <!-- <span v-if="pg_list.charge_order_export">
                <el-button style="margin-left: 10px" @click="ExportChargeDialog(scope.$index)" size="mini" type="primary">充次记录</el-button>
              </span> -->

              <span v-if="pg_list.charge_device">
                <el-button style="margin-left: 10px" size="mini"
                  @click="OpenModifyDialog(scope.$index)">设备命名</el-button>

              </span>


              <span v-if="pg_list.enable_device">
                <el-divider direction="vertical"></el-divider>
                <span v-if="enen">
                  <el-button size="mini" @click="EnableDisableDevice(scope.$index, 1)" type="success"
                    plain>设备启用</el-button>
                </span>
                <span v-if="enen == false">
                  <el-button size="mini" @click="EnableDisableDevice(scope.$index, 0)" type="info" plain>设备禁用</el-button>
                </span>

              </span>
              <span v-if="pg_list.bind_or_not">
                <el-button style="margin-left: 10px" size="mini" @click="OpenAllocateDialog(scope.$index, 0)"
                  type="primary" plain>设备分配</el-button>
                <!-- 设备分配 Start -->

              </span>


              <span v-if="pg_list.device_order_export">
                <el-divider direction="vertical"></el-divider>
                <el-popover placement="top" width="220" v-model="scope._vis_export_">
                  <div style="text-align: center; margin: 0">
                    <p>导出Excel报表</p>

                    <el-button icon="el-icon-tickets" @click="ExportExcelDialog(scope.$index)"
                      size="mini">使用记录</el-button>
                    <el-button icon="el-icon-pie-chart" style="margin-left: 10px"
                      @click="ExportChargeDialog(scope.$index)" size="mini">充次记录</el-button>
                  </div>
                  <el-button size="mini" type="primary" slot="reference">导出记录</el-button>
                </el-popover>

              </span>

              <span v-if="pg_list.delete_device">
                <el-divider direction="vertical"></el-divider>
                <el-popover placement="top" width="180" v-model="scope._vis2_">
                  <div style="text-align: center; margin: 0">
                    <p>确认删除设备"{{ tbdata[scope.$index].name }}"吗？删除后无法恢复</p>

                    <el-button size="mini" icon="el-icon-delete" type="danger" plain
                      @click="DeleteDeviceCommit(scope.$index)">确认删除</el-button>
                  </div>
                  <el-button size="mini" type="danger" plain slot="reference">设备删除</el-button>
                </el-popover>
              </span>

            </template>
          </el-table-column>
        </el-table>

        <div style="display: flex;align-items: center;justify-content:flex-start">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currPage"
            :page-sizes="[10, 20, 30, 50]" :page-size="currSize" layout="total, sizes, prev, pager, next, jumper"
            :total="currTotal">
          </el-pagination>


          <span style="margin-left: 40px">
            列表自动刷新:
            <el-switch style="margin-right: 7px" v-model="autoRefreshFlag" @change="handleSwitchChange">
            </el-switch>
            <span v-if="autoRefreshFlag">开</span>
            <span v-if="!autoRefreshFlag">关</span>
          </span>

          <span style="margin-left: 40px">
            <el-checkbox v-model="ordercheck" @change="onRefresh">按设备活跃时间排序</el-checkbox>
          </span>

        </div>
      </el-col>


    </el-row>

    <!-- 充值次数 Start -->
    <el-dialog title="设备次数充值" :visible.sync="RechargeTimesDialog" width="400px">
      <el-form v-loading="chargeLoading">
        <el-form-item label="充值次数：" label-width="100px">
          <el-input type="number" v-model="RechargeEditor" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <p style="margin-left: 16px"><i>当前剩余可分配次数:</i> <b style="margin-left: 4px">{{
        info.atimes
      }}</b></p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="RechargeTimesDialog = false">取 消</el-button>
        <el-button type="success" @click="RechargeCommit">充 次</el-button>
      </div>
    </el-dialog>
    <!-- 充值次数 END -->

    <!-- 修改床名字 Start -->
    <el-dialog title="修改名称" :visible.sync="modifyDeviceNameDialog" width="500px">
      <el-form>
        <el-form-item label="命名" label-width="80px">
          <el-input clearable v-model="modifyEditor" autocomplete="off" maxlength="16" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="modifyDeviceNameDialog = false">取 消</el-button>
        <el-button type="primary" @click="ModifyNameCommit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改床名字 END -->

    <el-dialog title="设备分配" :visible.sync="DeviceAllocateDialog" width="500px">
      <el-form>
        <el-form-item label="选择设备所属用户" label-width="160px">
          <el-select v-model="AllocValue" placeholder="请选择">
            <el-option v-for="item in AllocList" :key="item.uid" :label="item.name" :value="item.uid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="DeviceAllocateDialog = false">取 消</el-button>
        <el-button type="primary" @click="AllocateCommit">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 设备分配 END -->

    <!-- 导出报表 Start -->
    <el-dialog title="导出报表" :visible.sync="ExcelDialog" width="500px">

      <div class="block">
        <span style="margin-right: 5px">选择导出时间：</span>
        <el-date-picker v-model="excelTime" type="daterange" align="right" unlink-panels range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ExcelDialog = false">取 消</el-button>
        <el-button type="primary" @click="ExcelCommit">导 出</el-button>
      </div>
    </el-dialog>
    <!-- 导出报表 END -->


    <el-dialog :title="'设备定位 - ' + MapTitle" :visible.sync="MapDialog" width="1200px">

      <div class="block">
        <iframe :src="MapURL" frameborder="0" style="width: 100%; height: 500px;"></iframe>
      </div>
      <div slot="footer" class="dialog-footer">
        <!--  <el-select @change="MapGenUrl" style="margin-right: 30px" v-model="MapType" placeholder="地图类型">-->
        <!--    <el-option-->
        <!--        v-for="item in MapTypeoptions"-->
        <!--        :key="item.value"-->
        <!--        :label="item.label"-->
        <!--        :value="item.value">-->
        <!--    </el-option>-->
        <!--  </el-select>-->
        <el-button type="primary" @click="MapDialog = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!--    地图END-->

    <!-- 配置 Start -->
    <el-dialog title="设备配置" :visible.sync="DeviceConfigDialog" width="520px">
      <el-form>
        <el-form-item label="停机延时分钟" label-width="140px">
          <el-input-number v-model="FanDelayMinute" :min="1" :max="15"></el-input-number>
          分钟 <el-button style="margin-left: 15px" type="primary" @click="FanConfigCommit">保 存</el-button>
        </el-form-item>

        <el-form-item v-if="pg_list.device_config_root" label="风机DUTY" label-width="140px">
          <el-input-number v-model="FanGear" :min="0" :max="3" style="width: 90px;" controls-position="right"></el-input-number>
          <el-input-number v-model="FanDuty" step="5" :min="0" :max="100" style="width: 100px;" controls-position="right"></el-input-number>
          % <el-button style="margin-left: 15px" type="primary" @click="FanDutyConfigCommit">保 存</el-button>
        </el-form-item>

        <el-form-item v-if="pg_list.device_config_root" label="冬季模式开始时间" label-width="140px">
          <el-input-number v-model="WinterStartTime" :min="1" :max="90"></el-input-number>
          分钟 <el-button style="margin-left: 15px" type="primary" @click="WinterStartConfigCommit">保 存</el-button>
        </el-form-item>

        <el-form-item v-if="pg_list.device_config_root" label="冬季模式加热温度" label-width="140px">
          <el-input-number v-model="WinterStartTemp" :min="1" :max="60"></el-input-number>
          ℃ <el-button style="margin-left: 15px" type="primary" @click="WinterTempConfigCommit">保 存</el-button>
        </el-form-item>

        <el-form-item v-if="pg_list.device_config_root" label="升降电机升限" label-width="140px">
          <el-input-number v-model="MotorUpLimit" :min="1" :max="250"></el-input-number>×2
          <el-button style="margin-left: 15px" type="primary" @click="MotorUpLimitConfigCommit">保 存</el-button>
        </el-form-item>

        <el-form-item v-if="pg_list.device_config_root" label="FANSET" label-width="140px">
          <el-input-number v-model="fansetValue" :min="0" :max="255"></el-input-number>
          <el-button style="margin-left: 15px" type="primary" @click="FANSETConfigCommit">保 存</el-button>
        </el-form-item>

        <el-form-item v-if="pg_list.device_config_root" label="LOWFANSET" label-width="140px">
          <el-input-number v-model="lowfansetValue" :min="0" :max="255"></el-input-number>
          <el-button style="margin-left: 15px" type="primary" @click="LOWFANSETConfigCommit">保 存</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
    <!-- 配置 END -->

    <!-- 导出充次记录 +2024年1月29日 Start -->
    <el-dialog title="导出充次记录" :visible.sync="ChargeDialog" width="500px">

      <div class="block">
        <span style="margin-right: 5px">选择导出时间：</span>
        <el-date-picker v-model="excelTime" type="daterange" align="right" unlink-panels range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ChargeDialog = false">取 消</el-button>
        <el-button type="primary" @click="ExportChargeCommit">导 出</el-button>
      </div>
    </el-dialog>
    <!-- 导出充次记录 END -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      autoRefreshFlag: false, // 默认关闭自动刷新
      refreshTimer: null, // 定时器

      currPage: 1,
      currSize: 10,
      currTotal: 0,
      ordercheck: true,

      searchinput: "",
      searchinputSN: "",
      tbdata: [
        ],
      uselect: [{
        value: '',
        label: '不限'
      }],
      usvalue: '',
      sselect: [{
        value: '',
        label: '不限'
      }, {
        value: '在线',
        label: '在线'
      }, {
        value: '运行',
        label: '运行'
      }, {
        value: '离线',
        label: '离线'
      }, {
        value: '禁用',
        label: '禁用'
      }],
      ssvalue: '',

      // 地图
      MapDialog: false,
      MapTitle: '',
      MapURL: '',
      MapLoc: {},
      MapType: 'a',
      // 风机配置
      DeviceConfigDialog: false,
      ConfigSN: "",
      FanDelayMinute: 0,
      FanDuty: 0,
      FanGear:1,
      WinterStartTime: 0,
      WinterStartTemp: 0,
      MotorUpLimit:176,
      fansetValue:0,
      lowfansetValue:0,


      // 充次
      RechargeTimesDialog: false,
      RechargeEditor: "20",
      RechargeSN: "",
      chargeLoading: false,

      // 修改名字
      modifyDeviceNameDialog: false,
      modifyEditor: "",
      modifySN: "",
      // 设备分配
      DeviceAllocateDialog: false,
      AllocSN: "",
      AllocValue: "",
      AllocList: null,

      // 导出报表
      ExcelDialog: false,
      excelTime: "",
      ExcelSN: "",
      ExcelName: "",
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      orderHeader: {
        i: '序号',
        c: '剩余次数',
        s: '设备启动时间',
        e: '设备停止时间',
        u: '工作时长',
        m: '备注',
      },
      orderList: [],
      //device list excel
      deviceHeader:
      {
        sn: 'SN',
        name: '设备名称',
        model: '型号',
        status: '状态',
        counts: '剩余次数',
        today: '今日次数',
        month: '每月次数',
        total: '总次数',
        updateTime: '更新时间',
      },

      // 导出充次 + 2024年1月29日
      ChargeDialog: false,
      chargeorderHeader: {
        i: '序号',
        u: '充次操作账号',
        t: '充值次数',
        b: '充次前剩余',
        c: '操作时间',
        m: '备注',
      },
      chargeOrderList: [],

      enen: false,
      pg_list: {
        charge_device: false,
        device_config: false,
        rename_device: false,
        enable_device: false,
        bind_or_not: false,
        device_order_export: false,
        charge_order_export: false,
        delete_device: false,
        device_config_root: false,
      },
      btnwidth: 0,
    }
  },
  created() {
    this.info = this.$store.state.userInfo

    this.onGetVUList();
    this.PG_Query();
    this.onRefresh();

  },
  mounted() {
    if (this.autoRefreshFlag) {
      this.startRefreshTimer();
    }
  },
  beforeDestroy() {
    this.clearRefreshTimer();
  },
  methods: {
    handleSwitchChange(value) {
      if (value) {
        this.startRefreshTimer();
      } else {
        this.clearRefreshTimer();
      }
    },
    startRefreshTimer() {
      this.clearRefreshTimer(); // 清除之前的定时器
      this.refreshTimer = setInterval(() => {
        this.onRefresh();
      }, 5000); // 每5秒刷新一次
    },
    clearRefreshTimer() {
      if (this.refreshTimer) {
        clearInterval(this.refreshTimer);
        this.refreshTimer = null;
      }
    },
    // 启动设备
    async DeviceTrun(index, turn) {
      let res = await this.$api.turn_device({
        sn: this.tbdata[index].sn,
        turn: turn,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: res.msg,
          type: 'success',
        })
      }
    },
    // 导出报表
    ExportExcelDialog(index) {
      this.ExcelSN = this.tbdata[index].sn
      this.ExcelName = this.tbdata[index].name
      this.ExcelDialog = true
    },

    async ExcelCommit() {  // TODO  TEST
      let res = await this.$api.device_order_export({
        sn: this.ExcelSN,
        start: this.excelTime[0],
        end: this.excelTime[1]
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '导出成功',
          type: 'success',
        })
        this.orderList = res.data

        this.ExportOrderExcel()
      }

    },

    //////////////++++++++++++++////////// order excel //++++++++++++++++++++++//////////////////

    async ExportOrderExcel() {  //TODO V2
      console.log('ExportOrderExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let name = "设备报表-" + this.ExcelName + '(' + this.excelTime[0] + '-' + this.excelTime[1] + ').xlsx'
      let table = JSON.parse(JSON.stringify(this.orderList))
      let map = []
      let cols = Object.keys(this.orderHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.orderHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.orderHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 110
      }
      widthMap['A'] = 60
      widthMap['B'] = 70
      widthMap['F'] = 90

      let colConf = this.$Excel.makeColConfig({ ...widthMap }, 110)
      // 设置样式
      this.$Excel.setExportCellStyle(
        map,
        `A2:${end}`,
        {
          s: {
            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: 'dcdcdc' } },
            font: { color: { rgb: '000000' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
          return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
        }
      )
      this.$Excel.setExportCellStyle(
        map,
        `A2:${end}`,
        {
          s: {
            font: { color: { rgb: '000000' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
          return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
        }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
        map,
        `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
        {
          s: {
            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: '67c23a' } },
            font: { color: { rgb: 'ffffff' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          return currentRow === 0 ? newCell : cell // 隔行隔列上色
        }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    }, //end order excel
    // ------------------------------------------------------------------------------------------------------------

    // 导出充次记录 + 2024年1月29日
    ExportChargeDialog(index) {
      this.ExcelSN = this.tbdata[index].sn
      this.ExcelName = this.tbdata[index].name
      this.ChargeDialog = true
    },

    async ExportChargeCommit() {  // + 2024年1月29日
      let res = await this.$api.charge_order_export({
        sn: this.ExcelSN,
        start: this.excelTime[0],
        end: this.excelTime[1]
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '导出成功',
          type: 'success',
        })
        this.chargeOrderList = res.data

        this.ExportChargeOrderExcel()
      }

    },

    async ExportChargeOrderExcel() {  // + 2024年1月29日
      console.log('ExportChargeOrderExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let name = "设备充次记录-" + this.ExcelName + '(' + this.excelTime[0] + '-' + this.excelTime[1] + ').xlsx'
      let table = JSON.parse(JSON.stringify(this.chargeOrderList))
      let map = []
      let cols = Object.keys(this.chargeorderHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.chargeorderHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.chargeorderHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 110
      }
      widthMap['A'] = 50
      widthMap['B'] = 150
      widthMap['C'] = 90
      widthMap['D'] = 90
      widthMap['F'] = 100

      let colConf = this.$Excel.makeColConfig({ ...widthMap }, 110)
      // 设置样式
      this.$Excel.setExportCellStyle(
        map,
        `A2:${end}`,
        {
          s: {
            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: 'dcdcdc' } },
            font: { color: { rgb: '000000' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
          return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
        }
      )
      this.$Excel.setExportCellStyle(
        map,
        `A2:${end}`,
        {
          s: {
            font: { color: { rgb: '000000' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
          return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
        }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
        map,
        `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
        {
          s: {
            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: '1E90FF' } },
            font: { color: { rgb: 'ffffff' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          return currentRow === 0 ? newCell : cell // 隔行隔列上色
        }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    }, //end order excel
    // ------------------------------------------------------------------------------------------------------------


    // 启用禁用设备
    async EnableDisableDevice(index, en) {
      let res = await this.$api.enable_device({
        sn: this.tbdata[index].sn,
        en: en,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '操作成功',
          type: 'success',
        })
        this.onRefresh();
      }
    },
    // 充次弹窗
    OpenRechargeDialog(index) {
      this.RechargeSN = this.tbdata[index].sn
      this.RechargeTimesDialog = true
    },
    /////////////////////////////////////////// excel //////////////////////////////////////////////////

    ExportDeviceExcel() {  //TODO V2
      console.log('exportExcel() ')
      //let dev = window.sessionStorage.getItem('devname')
      let name =
        this.info.name +
        '-设备列表.xlsx' // TODO 加时间
      let table = JSON.parse(JSON.stringify(this.tbdata))
      let map = []
      let cols = Object.keys(this.deviceHeader).length
      let widthMap = {}
      let end = String.fromCharCode(64 + parseInt(cols)) + (table.length + 1)
      // 加入表头
      table.unshift(this.deviceHeader)
      // 数据字段过滤
      map = this.$Excel.filterExportData(table, [...Object.keys(this.deviceHeader)])
      // 单元格宽度循环设置
      for (let x = 1; x <= cols; x++) {
        widthMap[String.fromCharCode(64 + parseInt(x))] = 70
      }
      widthMap['A'] = 100
      widthMap['B'] = 120
      widthMap['I'] = 120

      let colConf = this.$Excel.makeColConfig({ ...widthMap }, 70)
      // 设置样式
      this.$Excel.setExportCellStyle(
        map,
        `A2:${end}`,
        {
          s: {
            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: 'dcdcdc' } },
            font: { color: { rgb: '000000' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
          return currentRow % 2 === 0 ? newCell : cell // 隔行隔列上色
        }
      )
      this.$Excel.setExportCellStyle(
        map,
        `A2:${end}`,
        {
          s: {
            font: { color: { rgb: '000000' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          // 回调参数，cell:原有数据，newCell:根据批量设置规则自动生成的样式，row:所在行数据，config:传入的配置,currentRow:当前行索引,currentCol:当前列索引，fieldKey:当前字段索引
          return currentRow % 2 === 1 ? newCell : cell // 隔行隔列上色
        }
      )
      // 设置样式
      this.$Excel.setExportCellStyle(
        map,
        `A1:${String.fromCharCode(64 + parseInt(cols))}1`,
        {
          s: {
            fill: { bgColor: { indexed: 64 }, fgColor: { rgb: '67c23a' } },
            font: { color: { rgb: 'ffffff' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          },
        },
        function (
          cell,
          newCell,
          row,
          config,
          currentRow,
          currentCol,
          fieldKey
        ) {
          console.log(row, config, currentCol, fieldKey)
          return currentRow === 0 ? newCell : cell // 隔行隔列上色
        }
      )
      // 导出excel
      this.$Excel.exportExcel(map, name, 'xlsx', {
        extend: {
          '!cols': colConf,
        },
      })
    },
    // 充次
    async RechargeCommit() {
      this.chargeLoading = true
      try {
        let res = await this.$api.charge_device({
          sn: this.RechargeSN,
          times: this.RechargeEditor.toString(),
        })
        console.log(res)
        this.chargeLoading = false
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: 'success',
          })
          this.onRefresh();
          this.RechargeTimesDialog = false
          if (this.info.atimes != -1 && res.atimes != -2) {
            this.info.atimes = res.atimes
            this.$store.dispatch('Login', this.info)
          }
        }
      } catch (error) {
        this.chargeLoading = false;
      }
    },
    // 修改名字
    OpenModifyDialog(index) {
      this.modifyEditor = this.tbdata[index].name
      this.modifySN = this.tbdata[index].sn
      this.modifyDeviceNameDialog = true
    },
    async ModifyNameCommit() {
      let res = await this.$api.rename_device({
        sn: this.modifySN,
        rename: this.modifyEditor,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.onRefresh();
      }
      this.modifyDeviceNameDialog = false
    },
    // 设备分配

    async GetKids() {


    },
    async OpenAllocateDialog(index) {
      this.AllocSN = this.tbdata[index].sn

      let res = await this.$api.device_owner({
        sn: this.AllocSN
      })
      if (res.status == 1) {
        this.AllocList = res.data
        this.AllocValue = res.uid
      }
      else {
        this.AllocValue = ""
      }
      this.DeviceAllocateDialog = true
    },
    async AllocateCommit() {
      let res = await this.$api.bind_or_not({
        sn: this.AllocSN,
        uid: this.AllocValue,
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.onRefresh();
      }
      this.DeviceAllocateDialog = false
    },

    // 设备分配
    async OpenDeviceConfigDialog(index) {
      this.ConfigSN = this.tbdata[index].sn
      let res = await this.$api.get_device_config({
        sn: this.ConfigSN
      })
      console.log(res)
      if (res.status == 1) {
        this.FanDelayMinute = res.data.fan_delay
        this.WinterStartTime = res.data.winter_time
        this.WinterStartTemp = res.data.winter_temp
      }
      else {
        this.FanDelayMinute = 0
        this.WinterStartTime = 0
        this.WinterStartTemp = 0
      }
      this.DeviceConfigDialog = true
    },
    async FanConfigCommit() {
      let res = await this.$api.device_config({
        sn: this.ConfigSN,
        key: "fan_delay",
        val: this.FanDelayMinute + "",
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '停机延时分钟设置成功',
          type: 'success',
        })
        // this.DeviceConfigDialog = false
      }
    },
    async WinterStartConfigCommit() {
      let res = await this.$api.device_config({
        sn: this.ConfigSN,
        key: "winter_time",
        val: this.WinterStartTime + "",
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '冬季模式开始时间设置成功',
          type: 'success',
        })
        // this.DeviceConfigDialog = false
      }
    },
    async FanDutyConfigCommit() {
      let res = await this.$api.device_config({
        sn: this.ConfigSN,
        key: "fan_duty_"+this.FanGear,
        val: this.FanDuty + "",
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '风机DUTY设置成功',
          type: 'success',
        })
        // this.DeviceConfigDialog = false
      }
    },
    async WinterTempConfigCommit() { //冬季模式温度设置
      let res = await this.$api.device_config({
        sn: this.ConfigSN,
        key: "winter_temp",
        val: this.WinterStartTemp + "",
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '冬季模式温度设置成功',
          type: 'success',
        })
        // this.DeviceConfigDialog = false
      }
    },
    async MotorUpLimitConfigCommit() { //升降电机升限
      let res = await this.$api.device_config({
        sn: this.ConfigSN,
        key: "motor_up_limit",
        val: this.MotorUpLimit + "",
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '升降电机升限设置成功',
          type: 'success',
        })
        // this.DeviceConfigDialog = false
      }
    },
    async FANSETConfigCommit() { //FANSET
      let res = await this.$api.device_config({
        sn: this.ConfigSN,
        key: "fan_set",
        val: this.fansetValue + "",
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: 'FANSET设置成功',
          type: 'success',
        })
        // this.DeviceConfigDialog = false
      }
    },
    async LOWFANSETConfigCommit() { //LOWFANSET
      let res = await this.$api.device_config({
        sn: this.ConfigSN,
        key: "low_fan_set",
        val: this.lowfansetValue + "",
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: 'LOWFANSET设置成功',
          type: 'success',
        })
        // this.DeviceConfigDialog = false
      }
    },

    async onGetVUList() {
      let res = await this.$api.username_list()
      console.log(res)
      if (res.status == 1) {
        if (res.u != null)
          this.uselect = [{
            value: '',
            label: '不限'
          }, {
            value: '0',
            label: '未分配'
          }, ...res.u]
      } else {
        this.uselect = [{
          value: '',
          label: '不限'
        }, {
          value: '0',
          label: '未分配'
        }]

      }
    },
    //////
    // 主刷新
    async onRefresh() {
      // let res = await this.$api.device_list({
      //   name: this.searchinput+"",
      //   ssn:this.searchinputSN+"",
      //   uid: this.usvalue,
      //   status: this.ssvalue
      // })
      let res = await this.$api.device_list_v2({
        name: this.searchinput + "",
        uid: this.usvalue,
        status: this.ssvalue,
        sn: this.searchinputSN + "",
        // le:this.leinput+"",
        p: this.currPage + "",
        z: this.currSize + "",
        sort: this.ordercheck,
      })
      console.log(res)
      if (res.status == 1) {
        this.tbdata = res.data
        if(this.currTotal != res.total){
          this.currPage = 1
          this.currTotal = res.total
          this.onRefresh()
        }
        
        if (this.ssvalue == "禁用") {
          this.enen = true
        } else {
          this.enen = false
        }
      }
    },
    // 获取页面上可用的按钮
    async PG_Query() {
      let res = await this.$api.pg_query({
        pg: this.pg_list,
      })

      // console.log("pg ->", res)
      if (res.status == 1) {
        this.pg_list = res.data
      }

      console.log("====>", this.pg_list)

      let btnNum = 0
      for (let k in this.pg_list)
        if (this.pg_list[k])
          btnNum++

      this.btnwidth = 430

      if (btnNum >= 4) {
        this.btnwidth = this.btnwidth + (btnNum - 4) * 90
      }


    },
    async DeleteDeviceCommit(index) {
      let res = await this.$api.delete_device({
        sn: this.tbdata[index].sn
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '删除成功',
          type: 'success',
        })
        this.onRefresh();
      }
    },
    handleSizeChange(val) {
      this.currSize = val
      this.currPage = 1
      this.onRefresh()
    },
    handleCurrentChange(val) {
      this.currPage = val
      this.onRefresh()
    },
    // 地图
    OpenMapDialog(index) {
      this.MapURL = ""
      try {
        this.MapLoc = JSON.parse(this.tbdata[index].loc);
        this.MapTitle = this.tbdata[index].name
        if (this.MapLoc.lat && this.MapLoc.lng) {
          this.MapGenUrl()
          this.MapDialog = true
        } else {
          this.$message({
            message: '设备定位失败',
            type: 'warning'
          });
        }
      } catch (error) {
        this.$message({
          message: '设备定位失败',
          type: 'warning'
        });
      }
    },
    MapGenUrl() {
      this.MapURL = `https://uri.amap.com/marker?position=${this.MapLoc.lng},${this.MapLoc.lat}&name=${this.MapTitle}&coordinate=wgs84&callnative=0`
    },
    // async onGetDeviceDetail() {   //TODO V2
    //   let res = await this.$api.device_detail({
    //     sn: this.tbdata[this.index].sn
    //   })
    //   console.log(res)
    //   if (res.status == 1) {
    //     this.detail = res.data
    //
    //   }
    //
    // },
  },
}
</script>

<style scoped>
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.detail-card .el-button {
  margin: 8px;
  margin-bottom: 14px;
  font-size: 16px;
  width: 92%
}
</style>
